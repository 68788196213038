import React from 'react';
import { NarmadaMission1 } from './NarmadaMission/NarmadaMission1';
// import NarmadaMission2 from './NarmadaMission/NarmadaMission2';
// import NarmadaMission3 from './NarmadaMission/NarmadaMission3';
import NarmadaMission0 from './NarmadaMission/NarmadaMission0';
export const Mission = () => {
  return (
    <div >
        <NarmadaMission0/>
        <NarmadaMission1/>
        {/* <NarmadaMission2 /> */}
        {/* <NarmadaMission3 /> */}
    </div>
  )
}
