import AboutUs0 from "./AboutUs/AboutUs0"
import AboutUs1 from "./AboutUs/AboutUs1"
import AboutUs2 from "./AboutUs/AboutUs2"
import AboutUs3 from "./AboutUs/AboutUs3"
const AboutUs = () => {
  return (
    <div>
        <AboutUs0 />
        <AboutUs1 />
        <AboutUs2 />
        <AboutUs3 />
    </div>
  )
}

export default AboutUs