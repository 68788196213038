import React from 'react'

const AboutUs3 = () => {
  return (
    <div name="aboutUs3" className='w-full bg-gradient-to-b from-gray-800 to-black text-white'>
    <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center'>
        <p className='text-xl'>
            दादागुरु को जानने और मानने वाले उन्हें अपना आराध्य मानते है उनके 
            हज़ारों अनुयायियों के पास  ऐसी अनेकानेक दिव्य अनुभूतियों का भंडार है जो दादागुरु के 
            सानिध्य में  उन्हें हुई। इस दैवीय सत्ता को अपना आराध्य मानने की इस कलयुग के आधार पर 
            एक औऱ परिभाषा है कि इस कठिनतम दौर में हमारे शीश पर हाथ रखकर यदि कोई कहता है
             कि मत डरो,मत विचलित हो,मत घबराओ,चिंता मत करो मैं तुम्हारी हर स्थिति,हर 
             परिस्थिति में स्थित हूँ तुम निर्भीक होकर अपना जीवन जिओ मैं तुम तक कोई दैहिक 
             भौतिक कष्ट नहीं आने दूंगा ।तुम बस अपने कार्यक्षेत्र में कर्मशील रहो।तो ऐसा कहने वाला 
             तो ईश्वर का कोई देवदूत या स्वयं ईश्वर ही हो सकता है।
         </p>
         <p className='text-xl'>
            सम्पूर्ण समर्थ परिवार  दादागुरु का सानिध्य पाकर  जितना आनंदित है और खुद को 
            ईश्वर का कृपापात्र मानता है उतना ही अपने आराध्य को निराहार चलता देखकर व्यथित भी है 
            सबका  ह्रदय वेदना से भरा हुआ है क्योंकि माँ नर्मदा के अस्तित्व और हमारा जीवन बचाने के लिए
             हमारे इष्ट खुद के जीवन को दाव पर लगाए हुए हैं।
            सत्ता और समाज की उदासीनता ने सत्याग्रह महाव्रत की अवधि को पीड़ा के शिखर तक बढ़ा दिया है। 
            सम्पूर्ण नर्मदासन्तानों की अरदास है की शीघ्र ही ये महाव्रत पूर्ण हो  क्योंकि कोई नहीं जानता 
            इसके गर्भ में कितने माणिक कितने पत्थर हैं पर इतना अवश्य है कि यह प्रलय का निग्रह औऱ शिव का अनुग्रह है।
         </p>
         <p className='text-xl'>
            औऱ अनुग्रह करने वाले भक्तवत्सल  दादागुरु किसी दैवीय अनुदानमात्र की ही परिणीति हैं 
            जो हमारी आध्यात्मिक विचार सरणियों की सीमा से परे  कोई देवदूत हैं ।
            वे जीवजगत के उद्धार के लिए समाज और सृष्टि के कल्याण के लिए आत्मसमर्पित हैं। 
            साधना के शिखर औऱ तपोबल के मेरु दादागुरु की इस अदभुत सत्ता एवम अवधूत रुप को हम प्रणाम करते हैं।🙏🙏🙏🙏🙏
         </p>
    </div>
</div>
  )
}

export default AboutUs3