import React from 'react'

const AboutUs2 = () => {
  return (
    <div name="aboutUs2" className='w-full bg-gradient-to-b from-black to-gray-800 text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center'>
            <p className='text-xl'>
                 बाल्यकाल में ही बालक के आचार व्यवहार और कृतित्व को देखकर  माता पिता
                  को अहसास होने लगा कि उनका बेटा असाधारण है और सबसे अलग है । 
                  विस्मय,चिंता,आशंका , खुशी ,भय वात्सल्य औऱ आकुलता मिश्रित भावों के साथ दादागुरु का लालन पालन होने लगा।  
                दादागुरु को उनकी माँ प्यार से मनु बुलाती थी औऱ अद्भुत सत्य यह है कि बालक 
                मनु का क्रीड़ास्थल बना एक बोधिवृक्ष  औऱ उसके पास  स्थित शिव की मड़िया। 
                सूत्र बताते हैं कि दादागुरु इस शिव स्वरूप को अपलक घण्टों निहारते थे फिर एक दिन उन्होंने 
                इसी मड़िया के शिवलिंग में भगवान शिव के तीसरे नेत्र को खुलते हुए देखा और आरम्भ हुई 
                दादागुरु की जीवजगत के उद्धार की अद्भुत यात्रा। 
             </p>
             <p className='text-xl'>
                दादागुरु मात्र 12 वर्ष की आयु से अपने प्रकटीकरण के उद्देश्य के मार्ग पर चल पड़े।
                आगे चल कर जबलपुर का सिद्ध घाट उनकी साधना स्थली बना ।सिद्ध घाट जो कि
                गौरीशंकर महाराज,दादाजी धूनीवाले जैसे अनेक महायोगियों का प्राकट्य स्थल है वही 
                दादागुरु समर्थ सद्गुरु की तपस्थल औऱ क्रीड़ा स्थल बना औऱ  प्रकृति पर्यावरण धर्म,धरा,धेनु संरक्षण सम्वर्धन ,
                तथा जीवजगत के अस्तित्व की रक्षा सुरक्षा के जिस पवित्र उद्देश्य के लिए दादागुरु इस धरा पर
                अवतरित हुए हैं उस उद्देश्य को मूर्तरूप देने का कार्य दादागुरु ने माँ नर्मदा के तट औऱ पथ से आरम्भ किया। 
             </p>
             <p className='text-xl'>
                'नदी नहीं तो सदी नही', 'सृजन का द्वार है विसर्जन का नहीं,'
                 'बंदगी करो गन्दगी नहीं' 'नर्मदा ही सत्य है' 
                 जैसे हज़ारों ब्रह्मसूत्र से समाज में जनजागृति लाने वाले दादागुरु ने 2010 
                 में " नर्मदा मिशन" की स्थापना कर संरक्षण सम्वर्धन की दिशा में एक अभूतपूर्व
                  अध्याय आरम्भ किया।नर्मदा मिशन ने दादागुरु के मार्गदर्शन और सानिध्य में अनेकों 
                  नए इतिहास गढ़े औऱ अकल्पनीय विश्व कीर्तिमान हासिल किए । जिसका विस्तृत विवरण आपको इसी अंक में मिलेगा।

             </p>
             <p className='text-xl'>
                सेवा और संकल्प का पर्याय बना नर्मदा मिशन आज के कठिनतम  दौर का एक मजबूत
                 स्तम्भ बन कर खड़ा हुआ है। देश विदेश में प्रकृति पर्यावरण तथा पवित्र नदियों के संरक्षण 
                 सम्वर्धन का विचार पहुंचाने वाले दादागुरु का ना तो कोई मठ है ना आश्रम और न ही कोई 
                 संचय।वे कहते हैं कि नर्मदा का पथ ही मेरा मठ है। इसी पथ पर अनवरत गतिशील रहते हुए 
                 दादागुरु विविध सृजनात्मक गतिविधियों से जनजागरण कर रहे हैं।उनका सन्देश है कि इस युग 
                 मे गुरु का सानिध्य प्रकृति चिंतन के बिना सम्भव नहीं है मानवीय समस्याओं का सार्थक और यथार्थ 
                 समाधान प्रकृति के पास है इसलिये हमें अपनी सम्वेदनाओं को प्रकृति से जोड़ना ही होगा प्रकृति 
                 से सहज सन्धि ही हमारी हर समस्या को दूर करेगी।क्योंकि प्रलय बार बार दस्तक दे रहा है इसलिये
                  इस आहट को सुनकर भी सचेत न होना हमारे अस्तित्व का खतरा बन जायेगा।
                   क्योंकि सृष्टि के सिद्धांतों और नियमो के विपरीत संकेत प्रकृति दे रही है बढ़ता तापमान,अत्यधिक बारिष, 
                   बाढ़, सूखा,पवित्र नदियों की मरणासन्न स्थिति हमारे जीवन पर खतरा है और इसी खतरे से जीवजगत को 
                   बचाने के लिए व मां नर्मदा का अस्तित्व बचाने के लिए दादागुरु विगत 3 वर्षों से अपनी सात सूत्रीय मांगों 
                   के साथ निराहार महाव्रत पर हैं। इस महाव्रत से उन्होंने इस सदी के सत्य को अर्थात माँ नर्मदा की जीवंतता को सिध्द किया है।
             </p>
        </div>
    </div>
  )
}

export default AboutUs2