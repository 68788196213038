import React from 'react'

export const NarmadaMission1 = () => {
  return (
    <div name="NarmadaMission1" className='w-full bg-gradient-to-b from-black via-gray-800  to-black text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bond inline border-b-4 border-gray-500'>
                    नर्मदा मिशन  संरक्षण,संकल्प ,सेवा,साधना और जनजागृति का गतिपथ
                    </p>
            </div>
            <p className='text-xl'>
                जन-जन का जीवन सुरक्षित और व्यवस्थित करने  महासंकल्प के साथ दादा गुरु ने 12 जनवरी 2010 
                में नर्मदा मिशन की स्थापना की।
            </p>
            <p className='text-xl'>
                जब किसी कार्य में मन की और प्राण की समग्र शक्ति लगती है तब वह संकल्प होता है
                 जो सिद्धि ,बुद्धि, औऱ ऊर्जा का वाहक भी होता है।जब संकल्प में गहरी सुदृढ़ता स्थापित हो जाती है 
                 और उसमें जीवन और प्राण लग जाता है तब संकल्प को पूर्ण करने के लिए किसी आधार  की आवश्यकता होती है
                  यही आधार है नर्मदा मिशन
                जिसका उद्देश्य प्रकृति पर्यावरण की रक्षा सुरक्षा, संरक्षण सम्वर्धन , प्रकृति प्रधान समाज की स्थापना, 
                तथा प्रत्येक व्यक्ति व जीव के जीवन को सुरक्षित एवम व्यवस्थित  करना है तथा मानव से समूह, समूह 
                से श्रृंखला औऱ श्रृंखला से क्रांति की  परिकल्पना को साकार करना है और हम गर्वित हैं कि यह परिकल्पना आज मूर्त रूप में समाज के समक्ष  है। 
            </p>
            <br />
            <p className='text-xl'>
                दुर्गम किन्तु लक्षयसाध्य राहों पर चलकर सफलता के शिखरों पर नर्मदा मिशन के श्रेष्ठ कार्य 
                रूपी ध्वज पूरी आन बान औऱ शान के साथ वैश्विक स्तर पर लहरा रहे हैं।
                ज्ञातव्य है कि मिशन की स्थापना के बहुत पहले से ही दादागुरु के सानिध्य औऱ मार्गदर्शन 
                में जल शुद्धिकरण, गौवंश का संरक्षण, प्रकृति पर्यावरण संरक्षण सम्वर्धन ,,दरिद्र नारायण सेवा, (मानव सेवा) 
                तथा प्राकृतिक कृषि के लिए जागरूकता इत्यादि अनेकानेक कार्य अनवरत जारी थे।
                नर्मदा मिशन के गठन  के बाद महायोगी दादागुरु के सानिध्य और मार्गदर्शन में नर्मदा मिशन ने विविध
                 क्षेत्रों में विश्व कीर्तिमान  हासिल किए ।
            </p>
            <br />
            <p className='text-xl'>
                <span className='font-bold'>27 नवंबर 2023 नर्मदापथ के महायोगी अवधूत दादागुरु के अखण्ड निराहार महाव्रत
                    साधना के 1137 दिन पूर्ण 
                    ओम्कारेश्वर से माँ नर्मदा की निराहार पैदल परिक्रमा का दूसरा चरण आरम्भ
                </span> 
                <br /><br />
                सम्पूर्ण ब्रह्मांड में उत्पत्ति,पालन और प्रलय होता  रहता है किंतु सृष्टिकर्ता  अपनी रचनाधर्मिता से निर्मित  
                सुंदर संसार को  अपनी परब्रह्म शक्ति से  समय से पूर्व प्रलय के गर्त में जाने से रोकता है  
                जिसका माध्यम स्वयम  ब्रह्मांड बनता है और ब्रह्मांड ही वह सन्देश भेजता है ईश्वर के किसी स्वरूप
               प्रतिनिधि को जो धरा पर सद्गुरु के रूप पूर्व से ही उपस्थित होते है। जो मानवीय असंवेदनशील व्यवहार से 
               उत्पन्न सृष्टि के असंतुलन को संतुलित करने का कार्य करते हैं।क्योंकि यदि यह कार्य स्वयं सृष्टि ने कुपित 
               होकर किया तो फिर प्रलय निश्चित है। 
                इस कलिकाल में वह स्वरूप हैं दादागुरु जो  प्रकृति के इसी असन्तुलन को सन्तुलित करने के पावन लक्ष्य के साथ 
                 इस सदी के  अतुलनीय अकल्पनीय यात्रा में   निराहार महाव्रत के साथ निकल पड़े हैं यह महाव्रत पवित्र नदियों के  
                 संरक्षण, सम्वर्धन  प्रकृति केंद्रित जीवन शैली. विकास और व्यवस्था के साथ आत्मनिर्भर भारत की जीवंत मिसाल 
                 बन कर हमारे समक्ष खड़ा हुआ है। 
                 <br /><br/>
                27 नवम्बर 2023 को इस महाव्रत के 1137 दिन पूर्ण हो रहे हैं  साथ ही दादागुरु की निराहार पैदल 
                माँ नर्मदा परिक्रमा का दूसरा चरण आरम्भ होने जा रहा है।
                <br /><br/>
                तिथियों के लेखापत्र पर जीवंत सत्य को प्रकट करता यह व्रत अकल्पनीय, निर्विकार, निष्काम
                 साधना सेवा का इतिहास बनता जा रहा है। यह महाव्रत जीवंत सत्य है जिसने मानवीय जीवन
                  की समस्त अवधारणाओं को आश्चर्यचकित कर दिया है यह इस सदी का सबसे बड़ा रहस्य है 
                औऱ इसीलिए ज्ञान विज्ञान को चुनौती देता दादा गुरु का यह अखंड महाव्रत देश दुनिया के लिए शोध
                 का विषय बन चुका है चूंकि जीवन जीने के सभी मानवीय आधारों को  छोड़कर  दादागुरु विगत 
                 1137 दिनों से अपनी साधना यात्रा में अनवरत चल रहे हैं।
                 <br  /><br/>
                17 अक्टूबर 2020 से मां नर्मदा औऱ प्रकृति पर्यावरण की साधना रक्षा सुरक्षा संरक्षण के लिए सिर्फ
                 माँ नर्मदा का अमृततुल्य जल को ग्रहण कर निराहार महाव्रत आरंभ  करने वाले दादागुरु ने इन 3 
                 वर्षों में माँ नर्मदा की जीवंत समर्थता औऱ जीवन शक्ति को देश दुनिया के सामने चरितार्थ किया है
                  उन्होंने सिद्ध किया कि माँ नर्मदा ही सत्य है। मां नर्मदा का पथ प्रत्यक्ष है।
                दादागुरु का यह अखण्ड निराहार व्रत मां नर्मदा के आंचल ,इस देव धरा के पर्वत
                 पहाड़ों जंगलों में छिपी शक्ति की समर्थ अभिव्यक्ति बना है ।
                इस युग का सबसे कठोर व्रत जिसमें इस सदी का सबसे बड़ा सन्देश छिपा है। कोई माने या ना माने 
                सत्य स्वयं शक्ति होता है मां नर्मदा के परम तपस्वी महायोगी सद्गुरु दादा गुरु का साधना महाव्रत 
                देश दुनिया के लिए रहस्य बनता जा रहा है विश्व के एकमात्र ऐसे अवधूत गुरु जिन्होंने अपना जीवन 
                राष्ट्रधर्म धरा धेनु प्रकृति एवं पर्यावरण  व मां नर्मदा के लिए समर्पित कर दिया उन्होंने ना किसी 
                पदार्थ संसाधन का संग्रह संचय किया और ना ही कहीं मठ या आश्रम बनाया वे तो हमेशा कहते हैं 
                मेरा मठ नर्मदा का पथ है यह घर गांव नगर ही देवालय ऐसे अवधूत दादा गुरु  प्रकृति और 
                पंचमहाभूत सगुण शक्तियों की जीवंत मूर्ति हैं। जिन्होंने राष्ट्र आराधना का मार्ग प्रशस्त किया है।
                <br /><br/>
                दादागुरु हमेशा कहते हैं कि  हमारा भारतीय हिन्दू दर्शन प्रकृति पर्यावरण पवित्र नदियों,पहाड़ों,धरा 
                को जीवंत प्रत्यक्ष शक्ति के रूप में मानता है औऱ पूजता है यही शक्ति हमारे जीवन धर्म संस्कृति सभ्यता व्यवस्था का मूल आधार है 
                 वर्तमान में इन्हीं प्राकृतिक सम्पदाओं दोहन शोषण चरम सीमा में हो रहा है अतः इसे संरक्षित करना हमारा प्रथम दायित्व है।
                  औऱ इसी प्राथमिक दायित्व के साथ चल रहा है दादागुरु का सत्याग्रह महाव्रत इस निर्विकार निष्काम सेवा साधना की 
                  प्रामाणिकता देखिए कि इन  3 वर्षों की साधना यात्रा में दादा गुरु ने प्रकृति पर्यावरण मां नर्मदा के संरक्षण संवर्धन को लक्ष्य कर
                   निराहार रहकर 3200 किलोमीटर की मां नर्मदा की पैदल परिक्रमा का प्रथम चरण पूर्ण किया है
                <br /><br/>
                परिक्रमा के अंतर्गत पथ पर देव वृक्षों की स्थापना के साथ प्रत्येक गांव नगर जिले में मां नर्मदा 
                के प्रति अपने दायित्व और कर्तव्यों का बोध कराने के लिए दादा गुरु ने अनवरत संवाद किए
                < br /><br/>

                इसके अतिरिक्त 11 बार मांधाता की परिक्रमा की 

                 नित्य 18 घंटे दिन रात चल रहा है साधना सेवा ध्यान जागरण
                 <br /> <br/>

                इसके अतिरिक्त संपूर्ण भारत के मां नर्मदा तीर्थ क्षेत्र तथा अन्य प्रांतों में अखंड सत्संग धर्म
                 सभा जन जागरण संवाद एवं सेवा कार्य कर रहे हैं दादा गुरु
                 अभी तक मां नर्मदा की पैदल परिक्रमा के अतिरिक्त लगभग 2 लाख किलोमीटर की जन
                  जागरण यात्रा पूर्ण कर चुके दादा गुरु ने भारत के अनेक राज्यों जैसे दिल्ली महाराष्ट्र 
                  गुजरात कर्नाटक उड़ीसा उत्तराखंड झारखंड छत्तीसगढ़ और पश्चिम बंगाल में जनजागृति 
                  हेतु अनवरत प्रकृति धर्म धरा, धेनु व नदियों पर केंद्रित विशेष संवाद सत्संग  किया

                   इस महा व्रत के दौरान दादा गुरु ने तीन बार रक्तदान कर ज्ञान विज्ञान के सभी मापदण्डों 
                   को खारिज किया चिकित्सयी दुनिया के लिए यह अचंभित करने वाला सत्य है। 

                   <br/><br/>
                भारत के अनेक विद्यालयों एवं महाविद्यालयों में समर्थ संवाद "नदी को जानो""नदी नहीं तो सदी 
                नहीं "है के महा वाक्य के साथ अनेक जिले प्रांतों में युवा संवाद की अखंड श्रंखला स्थापित की

                इसी तरह  पिछले वर्ष के चातुर्मास में ओमकारेश्वर तीर्थ क्षेत्र में  दादा गुरु निर्विकार ओंकार प्रकृति 
                की साधना में लीन रहे तथा उन्होंने प्रकृति केंद्रित जीवन शैली व्यवस्था और विकास संरक्षण के साथ 
                आत्मनिर्भर भारत के नव निर्माण के लिए जन-जन को जागृत कर मांधाता को राष्ट्र आराधना, 
                प्रकृति आराधना , उपासना का जीवंत केंद्र बनाया चातुर्मास के समय मांधाता पर्वत दादा गुरु की निर्विकार
                 पाठशाला के रूप में प्रतिस्थापित हुआ नित्य नर्मदा दर्शन के साथ देव वृक्षों की पूजन स्थापना धर्म 
                 धरा धेनु ओमकार स्वरूप मांधाता की शगुन शक्ति उपासना से आमजन को जोड़ने का कार्य दादा गुरु ने किया

                इसके अलावा इस वर्ष चातुर्मास में चक्रीय साधना करते हुए दादागुरु नें सम्पूर्ण नर्मदापथ के अनेकानेक 
                चिन्हित स्थानों पर देववृक्ष मूर्तियों की स्थापना ,साधना,जनसंवाद के साथ साथ माँ यमुना के 
                संरक्षण सम्वर्धन की मुहिम आरम्भ करते हुए दिल्ली से मथुरा तक कि पैदल यात्रा 28 अक्टूबर से 5 नबम्बर तक पूर्ण की।
                सिर्फ नर्मदाजल ग्रहण कर निराहार महाव्रत कर रहे दादागुरु को  पिछले माह तीन वर्ल्ड रिकॉर्ड्स एक साथ प्रदान किए गए।
                अतः हमें समझना होगा कि धर्म सेवा और विकास के नाम पर समाज और परिवार को दिशा 
                भ्रमित करने वाले लोगों के बीच दादा गुरु के रूप में सत्य चल रहा है सत्य को कभी प्रमाण की आवश्यकता नहीं होती 
                क्योंकि सत्य स्वयं में प्रमाण है 
                हमें  इस सत्य को स्वीकार करना होगा और सत्य चरित्र के साथ चलना होगा क्योंकि इतिहास साक्षी है कि जब जब सत्य चरित्र की अवहेलना और अनदेखी हुई है तो समाज को उसका मूल्य चुकाना पड़ा है।
                    <br /> <br />
                न भूतो न भविष्यति द्वितीयो न अस्ति
                न ऐसा कोई हुआ है न ऐसा कोई होगा महायोगी अवधूत दादागुरु ही प्रकृति और पंच 
                महाभूतों व सगुन शक्ति उपासना राष्ट्र आराधना की जीवंत सत्यमूर्ति हैं।
                अब 27 नबम्बर 2023 से दादागुरु की निराहार पैदल नर्मदा परिक्रमा का द्वितीय चरण आरम्भ हो रहा है।
                तो आइए इसलिए इस सदी की निर्विकार सत्यमूर्ति मंगलमूर्ति के संग चले सत्य की ओर कदम बढ़ाएं और दादा 
                गुरु की अखंड निराहार महा व्रत साधना में शामिल होकर एक निर्विकार अनाम महायोगी के सेवा महाव्रत साधना
                 में अपना योगदान दें।

                क्योंकि देश दुनिया में माटी के लिए पानी के लिए प्रकृति संरक्षण संवर्धन के लिए ऐसा जीवन जीने वाला कोई दूसरा नहीं हुआ है।
                हमारा परम सौभाग्य है जो इतनी सरलता सहजता से हमें इस महायोगी का सानिध्य सत्संग और मार्गदर्शन मिल रहा है
                 अतः हम दादागुरु के  निर्विकार महाव्रत में छिपे जीवंत सत्य को आत्मसात करें।औऱ सत्य के साथ चलें और सबसे
                  बेहतर सुरक्षित जीवन के लिए कदम बढ़ाए क्योंकि यह अस्तित्व को बचाने की मुहिम है इसके लिए यथासंभव देववृक्ष 
                  मूर्तियों को स्थापित करें कम से कम एक वृक्ष को संरक्षित करने का संकल्प लें और उसे संरक्षित करें  दादा गुरु की 
                  प्रकृति संरक्षण संवर्धन की निष्काम सेवा को ध्यान में रखते हुए हम सभी अपने खुद के अस्तित्व को बचाने की मुहिम
                   का हिस्सा बने औऱ सदी की इस अकल्पनीय अविश्वसनीय सेवा महाव्रत के साक्षी बने। 
                क्योंकि प्रकृति अपने दोहन से क्षुब्ध है और बार बार विनाश के संकेत दे रही है तो आइए प्रकृति की चेहरे की मुस्कान लौटा 
                कर खुद के लिए जीवन भर की खुशियाँ माँग लें। यही आह्वान दादागुरु का है।
                जय ॐ जय ॐ ,जय ॐ मां
            </p>
        </div>
        </div>
  )
}
