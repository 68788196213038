import React from 'react'

const ContactUs = () => {
  return (
    <div name='contactUs' className='w-fulls
     bg-gradient-to-b from-black to-gray-800  text-white'> 
        <div className='flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>Contact Us</p>
                <p className='py-6'>Submit the form below to get in touch with us.</p>
            </div>

            <div className='flex justify-center items-center'>
                <form action='https://getform.io/f/824a37f0-9553-4768-84e1-1cf6cc2ff545' method='POST'
                 className='flex flex-col w-full md:w-1/2'>
                    <input 
                     type='text' 
                     name='name' 
                     placeholder='Enter your name' 
                     className='p-2 bg-transparent border-2 rounded-md
                      text-white focus:outline-none'
                    />
                    <input 
                     type='text' 
                     name='phone' 
                     placeholder='Enter your phone number' 
                     className='p-2 my-4 bg-transparent border-2 rounded-md
                      text-white focus:outline-none'
                    />
                    <textarea
                     name='message'
                     rows='10'
                     placeholder='Enter your message'
                     className='p-2 bg-transparent border-2 rounded-md
                     text-white focus:outline-none'
                    ></textarea>

                    <button className='text-white bg-gradient-to-b
                    from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex 
                    item-center rounded-md hover:scale-110 duration-300'
                    >Let's talk</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ContactUs