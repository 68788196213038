import React from 'react';
import VideoBackground from './VideoBackground';
//import bgImage from '../assets/BackgroundImages/bg_1.jpg' className='h-screen w-full bg-image
const Home = () => {
  return (
    <div name="home">
      <VideoBackground />
    </div>
  )
}

export default Home

                
                